.contact-container {

    .contact-panels-container {
        display: flex;
        gap: 50px;
    }


    h1 {
        color: #DCDCDC;
    }
}