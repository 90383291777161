@media only screen and (max-width: 1080px) {
    .overlay-player-container {
        background-color: rgba(0,0,0,0.8);
        height: 100vh;
        left: 50%;
        position: fixed;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100vw;
        z-index: 1000;

        .overlay-player-video-container {
            height: 60%;
            left: 50%;
            position: relative;
            top: 55%;
            transform: translate(-50%, -50%);
            width: 95%;
        }
    }
}

@media only screen and (min-width: 1080px) {
    .overlay-player-container {
        background-color: rgba(0,0,0,0.8);
        height: 100vh;
        left: 50%;
        position: fixed;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100vw;
        z-index: 1000;

        .overlay-player-video-container {
            height: 60%;
            left: 50%;
            position: relative;
            top: 55%;
            transform: translate(-50%, -50%);
            width: 75%;
        }
    }
}