body {
  box-sizing: border-box;
  color: #363636;
  margin: 0;
  font-family: 'Roboto Condensed'
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,h2,h3,h4,h5 {
  margin: 0;
}

h1 {
  font-size: 40px;
}

h2 {
  font-size: 36px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 20px;
}

h5 {
  font-size: 18px;
}