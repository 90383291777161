.product-design-btn-container {
    border: 2px solid #363636;
    border-radius: 100px;
    cursor: pointer;
    width: fit-content;

    p {
        font-size: 16px;
        margin: 0;
        padding: 12px 40px;
    }
}