.hbo-btn-container {
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    height: 44px;
    justify-content: center;
    width: 128px;

    h3 {
        color: #FFFFFF;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 14px;
        font-weight: 700;

    }
}

.fill {
    background-color: #3D3D3D;
}

.nofill {
    border: 2px solid #FFFFFF;
}